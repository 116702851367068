// eslint-disable-next-line
import React from 'react'
import styled from '@emotion/styled'
import FormContact from '../atoms/form-contact';
import Header2 from '../atoms/header-2';

// Styles
const Container = styled.section`
  max-width: 750px;
  width: 100%;
  height: auto;
`

const ContactForm = () => (
  <Container>
    <Header2 fontFamily='Satisfy' color='#FFFFFF' spacing='0px'>Send a Message</Header2>
    <FormContact/>
  </Container>
)

export default ContactForm
import React from "react"
import Layout from "../components/layout"
import styled from '@emotion/styled'

// Components
import Masthead from "../components/organisms/masthead"
import ContactForm from "../components/organisms/contact-form"
import Footer from "../components/organisms/footer"
import GridTwo from "../components/organisms/grid-two"
import Header1 from "../components/atoms/header-1"
import Paragraph1 from "../components/atoms/paragraph-1"
import Paragraph2 from "../components/atoms/paragraph-2"

// Images
import ImgMastheadContact from "../components/images/_masthead/img-masthead-contact"
import ImgFooterContact from "../components/images/_footer/img-footer-contact"
import SEO from "../components/organisms/seo"

// Styles
const Block = styled.section`
  max-width: 400px;
  padding: 0 30px;
  margin-bottom: 60px;
  @media(max-width: 500px) {
    padding: 0 15px;
  }
`
const ContainerForm = styled.section`
  display: flex;
  justify-content: center;
  padding: 90px 30px 90px 30px;
  width: 100%;
  @media(max-width: 500px) {
    padding: 90px 15px 90px 15px;
  }
  
`
const B = styled.b`
  font-weight: 900;
  color: #000000;
`

// Data
const dataContact = [
  {
    title: 'Speaking Inquiries',
    text: [
      'CONFERENCES, RADIO, CHURCH EVENTS.',
      'If you would like to hear me speak about any of my books, or share my personal testimony, use the form above and I will get back to you soon!',
    ],
  },
  {
    title: 'Letters',
    text: [
      `AS A WRITER, I LOVE LETTERS!`,
      `If you would like to send letters or parcels by mail my way, I would love to hear from you! Please use the address below when writing!`,
    ],
    endLines: [
      'Attn: Beverly Hubbard',
      'PO Box 549',
      'Sanford, FL, 32772-0549'
    ]
  },
]

const ContactPage = () => (
  <Layout>

    <SEO title="Contact | Beverly Hubbard" keywords={[`beverly hubbard`, `beverly`, `hubbard`, `christian author`, `christian`, `author`, `the story of the bible`, `book writer`, `christian book author`, `contact`, `write a letter`]} />

    <Masthead image={<ImgMastheadContact/>}>
      <ContainerForm>
        <ContactForm/>
      </ContainerForm>
    </Masthead>

    <GridTwo>
      {dataContact && 
        dataContact.map((item, i) => (
          <Block key={i}>
            <Header1>{item.title}</Header1>

            {item.text &&
              <>
                {item.text.map((subItem, j) => {
                  if(j === 0) {
                    return(
                      <Paragraph1 key={j}>
                        <B key={100*j}>{subItem} </B>
                      </Paragraph1>
                    )
                  } else {
                    return(
                      <Paragraph1 key={j}>
                        {subItem}
                      </Paragraph1>
                    )
                  }
                })}
              </>
            }

            {item.endLines && 
              item.endLines.map((subItem, j) => (
                <Paragraph2 key={1000*j} fontWeight='900'>{subItem}</Paragraph2>
              ))
            }
            
          </Block>
        ))
      }
    </GridTwo>

    <Footer
      image={<ImgFooterContact/>}
    />
  </Layout>
)

export default ContactPage

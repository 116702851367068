import React from 'react'
import styled from '@emotion/styled'

// Styles
const Container = styled.section`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 90px auto 30px auto;
  max-width: 980px;
  padding: 60px;
  @media (max-width: 720px) {
    padding: 60px 30px;
  }
`

const GridTwo = ({ children }) => (
  <Container>
    {children}
  </Container>
)

export default GridTwo
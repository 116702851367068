import React from 'react'
import { navigate } from 'gatsby'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import Button1 from './button-1';

const ListErrors = styled.ul`
  list-style: none;
  margin: 0;

  padding: 15px;
  background: #FFFFFF;
  border: 2px solid #FF6B6B;
`

const ItemError = styled.li`
  color: #FF6B6B;
  font-family: Quicksand;
  font-size: 1em;
  font-style: normal;
  font-weight: 300;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
`

// Styles
const Form = styled.form`
`
const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  p:nth-of-type(2n) {
    float: right;
    @media (max-width: 600px) {
      float: none;
      width: 100%;
    }
  }
  p:nth-of-type(2n+1) {
    float: left;
    @media (max-width: 600px) {
      float: none;
      width: 100%;
    }
  }
  &:last-child {
    p {
      width: 100%;
      float: none;
    }
  }
`
const P = styled.p`
  position: relative;
  width: calc(50% - 8px);
  margin-bottom: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
`
const Label = styled.label`
  position: absolute;
  top: 15px;
  left: 15px;
  color: #8BC4A2;
  font-family: Open Sans;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  pointer-events: none;
  transition: 0.2s
`
const Input = styled.input`
  width: 100%;
  background: #FFFFFF;
  border: solid 2px transparent;
  box-sizing: border-box;
  padding: 20px 15px 10px 15px;
  color: #828282;
  font-family: Open Sans;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 300;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  &:focus, &:-webkit-autofill {
    color: black;
    background: white;
    border: solid 2px #8BC4A2;
    + label {
      top: 3px;
      font-size: 0.725em;
    }
  }
`
const Textarea = styled.textarea`
  width: 100%;
  background: #FFFFFF;
  border: solid 2px transparent;
  box-sizing: border-box;
  padding: 15px;
  color: #828282;
  font-family: Open Sans;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 300;
  outline: none;
  min-height: 200px;
  resize: vertical;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  &:focus, &:-webkit-autofill {
    color: black;
    background: white;
    border: solid 2px #8BC4A2;
    + label {
      top: 3px;
      font-size: 0.725em;
    }
  }
  @media (max-width: 600px) {
    height: 400px;
  }
`

// Encode Function for Data
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class FormContact extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      Subject: 'Website Contact Form',
      Name: '',
      Email: '',
      Message: '',
      errors: [],
    }
    this.baseState = this.state
  }

  handleChange = e => {
    const { target } = e
    
    if(target.value.length > 0) {
      if(target.nextSibling.style.color.length > 0) {
        target.nextSibling.removeAttribute('style')
      }
      if(target.localName === 'input') {
        target.nextSibling.style.top = '3px'
        target.nextSibling.style.fontSize = '0.725em'
      }
      if(target.localName === 'textarea') {
        target.nextSibling.style.top = '3px'
        target.nextSibling.style.fontSize = '0.725em'
      }
    } 
    else {
      target.nextSibling.removeAttribute('style')
    }

    this.setState({ [target.name]: target.value });
  };

  validate = () => {
    const formLength = this.formEl.length
    const errors = []
    
    if (this.formEl.checkValidity() === false) {
      for(let i=0; i<formLength; i++) {
        const elem = this.formEl[i]
        
        if (!elem.validity.valid) {
          document.querySelector(`input[name=${elem.name}]`).nextSibling.style.color = '#FF6B6B'
          errors.push('* Error on Field: ' + elem.name + ' - ' + elem.validationMessage)
        }
      }

      this.setState({errors})
      return false;

    } else {
      this.setState({errors: []})
      return true
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if(this.validate()) {
      const form = e.target
      fetch('/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state
        })
      })
      .then(() => {
        navigate('/thank-you')
      })
      .catch(error => {
        this.validate()
        this.setState({isValidated: true})
      })
    }
  }

  componentWillUnmount() {
    this.setState(this.baseState)
  }

  render() {
    const props = {...this.props};
    let classNames = [];
    
    if (props.className) {
      classNames = [...props.className];
      delete props.className;
    }

    if (this.state.isValidated) {
      classNames.push('.was-validated');
    }

    return (
      <>
      {this.state.errors.length > 0 && 
        <ListErrors>
            {this.state.errors.map((item, i) => (
              <ItemError key={i}>
                {item}
              </ItemError>
            ))}
        </ListErrors>
      }
      <Form ref={form => this.formEl = form} onSubmit={this.handleSubmit} {...props} name='Contact' action='/thank-you' method='POST' data-netlify='true' data-netlify-honeypot='message-bot' noValidate>
        <Fieldset>
          <P>
            <Input type='text' name='Name' id='name' onChange={this.handleChange} required />
            <Label htmlFor='Name'>Name*</Label>
          </P>
          <P>
            <Input type='email' name='Email' id='email' onChange={this.handleChange} required />
            <Label htmlFor='email'>Email*</Label>
          </P>
        </Fieldset>
        <Fieldset>
          <P css={css`display: none`}>
            <Input type='text' name='subject' id='subject' defaultValue={this.state.subject}/>
          </P>
          <P css={css`display: none`}>
            <Label>Message Bots:</Label>
            <textarea name='message-bot'/>
          </P>
          <P>
            <Textarea id='message' name='Message' rows={11} onChange={this.handleChange} />
            <Label htmlFor='Message'>Message...</Label>
          </P>
          <P css={css`text-align: right`}>
            <Button1 name='Submit' type='submit'>
              Send
            </Button1>
          </P>
        </Fieldset>
      </Form>
      </>
    )
  }
}